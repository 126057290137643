import {
  Home,
  ReportForm,
  Support,
  Apply,
  NotFound,
  Login,
  Success,
  One,
  Admin,
  RequestDetail,
  AdminDetail,
  Callback,
  Profile,
  Note,
  AdminManage,
  EmailSignup,
  EmailSignin,
  GithubCallback,
  About,
  Analytics,
} from './pages';

const routes = (isAuthenticated) => [
  { path: "/", element: isAuthenticated ? <Home /> : <Login /> },
  { path: "/report", element: isAuthenticated ? <ReportForm /> : <Login /> },
  { path: "/support", element: isAuthenticated ? <Support /> : <Login /> },
  { path: "/apply", element: isAuthenticated ? <Apply /> : <Login /> },
  { path: "/login", element: <Login /> },
  { path: "/success", element: isAuthenticated ? <Success /> : <Login /> },
  { path: "/one", element: isAuthenticated ? <One /> : <Login /> },
  { path: "/admin", element: isAuthenticated ? <Admin /> : <Login /> },
  { path: "/requestdetail", element: isAuthenticated ? <RequestDetail /> : <Login /> },
  { path: "/admindetail", element: isAuthenticated ? <AdminDetail /> : <Login /> },
  { path: "*", element: isAuthenticated ? <NotFound /> : <Login /> },
  { path: "/callback", element: <Callback /> },
  { path: "/profile", element: isAuthenticated ? <Profile /> : <Login /> },
  { path: "/admin/manage", element: isAuthenticated ? <AdminManage /> : <Login /> },
  { path: "/note", element: <Note /> },
  { path: "/email-signup", element: isAuthenticated ? <Home /> : <EmailSignup /> },
  { path: "/email-signin", element: isAuthenticated ? <Home /> : <EmailSignin /> },
  { path: "/github/callback", element: isAuthenticated ? <Home /> : <GithubCallback /> },
  { path: "/about", element: <About /> },
  { path: "/analytics", element: isAuthenticated ? <Analytics /> : <Login /> },
];

export default routes;
